<template>
  <div style="background-color: #fafafa">
  <GridList title="预约信息" portName="osp.visitor.VisitorReserve.getVisitorReserves" :request-data="{'visitor.id': visitorID}"
            :dataList="dataList" style="margin: 4.26667vw;" ref="grid">
    <ListCard
        v-for="item in dataList"
        :key="item.id"
        :onDel="onDel"
        :onRead="onRead"
        :id="item.id">

      <div class="item"  @click="onEdit(item.id, item['visitReasonDic.code'])">
        <van-image class="image" round  :src="require('../../assets/img/访.png')"/>
        <div class="content">
          <div class="title fs16">{{ item['interviewedUser.name'] }}</div>
          <div class="tags fs12"><span style="color: #129d67">{{item['visitReasonDic.name']}}</span></div>
          <div class="time fs12">{{item['code']}}</div>
        </div>
        <div class="fs12">     <van-tag :type="getTagType(item['statusDic.code'])">{{ item['statusDic.name'] }}</van-tag></div>
      </div>

    </ListCard>
  </GridList>
  </div>
</template>

<script>
import _ from "lodash";
import GridList from "../../components/GridList";
import ListCard from "../../components/ListCard";
import {ref} from "vue";

export default {
  components: {ListCard, GridList},
  name: "VisitorCarList",
  data() {
    return {
      visitorID: '',
      dataList: [],
    };
  },
  methods: {
    onAdd: function () {
      this.$router.push({name: 'visitorReserve'})
    },
    onEdit: function (id, code) {
      if (code == 'ZYSG') {
        this.$router.push({name: 'constructionReserve', params: {id: id}});
      } else {
        this.$router.push({name: 'visitorReserve', params: {id: id}});
      }
    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      }).then(() => {
        var option = {
          portName: 'osp.visitor.VisitorReserve.delVisitorReserve',
          data: {ids: [id]},
          needLoading: false,
          successCallback: (data) => {
            this.$notify({type: 'success', message: '删除成功'})
            this.$refs.grid.onRefresh();
          }
        }
        this.$sapi.callPort(option);
      })
          .catch(() => {

          });
    },
    onRead: function (id) {
      var option = {
        portName: 'osp.visitor.VisitorReserve.getVisitorReserve',
        data: {id: id},
        needLoading: false,
        successCallback: (data) => {
          this.$router.push({name: 'wfLog', params: {id: data.entities[0].wfProcess}})
        }
      }
      this.$sapi.callPort(option);
    },
    getTagType(code) {
      switch (code) {
        case 'VISITOR_RESERVE_STATUS_APPROVING':
          return 'warning ';
        case 'VISITOR_RESERVE_STATUS_PASS':
          return 'success ';
        case 'VISITOR_RESERVE_STATUS_REJECT':
          return 'danger  ';
        default:
          return 'primary';
      }
    }
  },
  created: function () {
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;
  }
}

</script>

<style scoped>
.container {
  min-height: 200px;
  padding: 10px;
  background: white;
}
.header {
  display: flex;
  padding-bottom: 10px;
}
.header > .title {
  flex: 1;
}
.header > .more {
  color: #888;
}
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}
.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.item > .title {
}
.item .status_green {
  color: #519874;
}
.item .status_red {
  color: #f83817;
}
.item > .content {
  flex: 1;
}
.item .time {
  color:#666;
}
</style>