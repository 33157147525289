<template>
  <van-nav-bar
      :border="false"
      :left-arrow="false"
      title=""
      style="
      --van-nav-bar-background-color: var(--van-primary-color);
      --van-nav-bar-title-text-color: #fff;
      --van-nav-bar-icon-color: #fff;
      --van-nav-bar-text-color: #fff;
    "
  />
  <van-pull-refresh
      style="background-color: #1989fa;--van-pull-refresh-head-text-color: #000000"
      head-height="40"
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh">
    <div class="section" style="background: var(--van-primary-color)">
      <!-- 基本信息 -->
      <div class="userinfo">
        <van-image
            class="avatar"
            round
            :src="require('../../assets/img/访.png')"
        />
        <div class="content" @click="$router.push('visitorInfo')">
          <div style="display: flex; align-items: center">
          <span class="fs16" style="color: #fff">{{ name }}</span
          ><span class="tag">{{ authStatus }}</span>
          </div>
          <div class="fs12" style="color: #000000">
            <span>认证有效期：{{ passValidDate }}</span>
          </div>
        </div>
      </div>
      <!-- 身份码 -->
      <div
          style="
        background: #2a1659;
        display: flex;
        align-items: center;
        padding: 5.33333vw 5.33333vw 5.86667vw 5.33333vw;
        border-radius: 1.33333vw 1.33333vw 0 0;
        margin: 0 3.73333vw;
      "
          @click="getRQCode"
      >
        <span class="fs16" style="color: #e9bd93; flex: 1">身份码</span>
        <div class="fs12" style="color: #8b9bd9">
          <span>点击查看身份码</span>
          <van-icon name="arrow"/>
        </div>
      </div>

    </div>
  </van-pull-refresh>


  <VisitorReserveList style="background-color: #ffffff"/>
  <van-dialog v-model:show="showDialog" title="身份码" style="text-align: center">
    <van-image :src="imgUrl"/>
  </van-dialog>
</template>

<script>

import VisitorReserveList from "../visitor/VisitorReserveList";
import _ from "lodash";

export default {
  components: {
    VisitorReserveList
  },
  created() {
    var visitor = this.$sapi.storage.getItemJson("visitor");
    this.name = visitor.name;
    var visitorID = visitor.id;
    this.idCode = visitor.idCode;
    this.getVisitorReserve(visitorID);
  },
  data() {
    return {
      name: '',
      authStatus: '未认证',
      passValidDate: '无',
      idCode: '',
      showDialog: false,
      imgUrl: '',
      isLoading: false
    };
  },
  methods: {
    onRefresh() {
      var visitor = this.$sapi.storage.getItemJson("visitor");
      this.name = visitor.name;
      var visitorID = visitor.id;
      this.getVisitorReserve(visitorID);
    },
    getVisitorReserve(visitorID) {
      var option = {
        portName: 'osp.visitor.VisitorAuth.getVisitorAuths',
        data: {'visitor.id': visitorID, 'authStatusDic.code': 'VISITOR_AUTH_STATUS_VERIFIED'},
        needLoading: false,
        pageIndex: 0,
        pageSize: 1,
        successCallback: (data) => {
          this.isLoading = false;
          var entities = data.entities;
          var entity = entities[0];
          if (!_.isEmpty(entity)) {
            var date = entity['passValidDate'];
            date = date.replace(/-/g,'/');
            if (!_.isEmpty(date)) {
              this.authStatus = '已认证';
              this.passValidDate = new Date(date).pattern('yyyy年MM月dd日');
              this.reserveID = entity.id;
            }
          }
        }
      }
      this.$sapi.callPort(option);
    },
    getRQCode() {
      var id = this.idCode;
      if (_.isEmpty(id)) {
        return;
      }
      var requestUrl = "/tool/getbarcode?code=" + encodeURIComponent(id);
      // 高度
      var height = 300;
      requestUrl += '&height=' + height;
      // 宽度
      var barcodeWidth = 300;
      requestUrl += '&width=' + barcodeWidth;
      // 格式
      var barcodeFormat = 'QR_CODE';
      requestUrl += '&type=' + barcodeFormat;
      this.$sapi.get(requestUrl).then((data) => {
        this.showDialog = true;
        this.imgUrl = this.$sapi.baseUrl + requestUrl;
      });
    }
  },
};
</script>

<style scoped>
.userinfo {
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.userinfo > .avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.userinfo > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.btn-group {
  display: flex;
  background: white;
  padding-bottom: 16px;
}

.btn-group-item {
  flex: 1;
  color: #999;
  text-align: center;
}

.btn-group-item-icon {
  font-size: 20px;
  background: #6a9bec;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: white;
  line-height: 50px;
  text-align: center;
  margin-bottom: 8px;
}

.wave {
  width: 375px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  top: -180px;
  transform: scale(2, 2);
  box-shadow: 0 0 0 20px #fff;
}
</style>